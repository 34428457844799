import { useAuth0 } from '@auth0/auth0-react';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import Layout from '../components/utils/Layout/Layout.component';
import { WebChatContext } from '../contexts/WebChat.context';
import Meta from '../components/utils/Meta/Meta.util';
import { AuthContext } from '../contexts/Auth.context';
import useDynamicComponent from '../hooks/useDynamicComponent/useDynamicComponent.hook';
import { StoryblokEntry } from '../interfaces/storyblok/StoryblokEntry.interface';
import getMetaData from '../utils/getMeta/GetMeta.util';
import BreadcrumbProps from '../interfaces/Breadcrumb/Breadcrumb.interface';
import getRequiredHeaderBlok from '../utils/getRequiredHeaderBlok/getRequiredHeaderBlok.util';
import getRequiredFooterBlok from '../utils/getRequiredFooterBlok/getRequiredFooterBlok.util';
import DynamicComponent from '../components/utils/DynamicComponent/DynamicComponent.component';

interface StoryblokEntryDataProps {
  storyblokEntry: StoryblokEntry;
}

interface PageContext {
  breadcrumb: BreadcrumbProps;
}

const StoryblokEntryTemplate: React.FC<
  PageProps<StoryblokEntryDataProps, PageContext>
> = ({ data, location, pageContext, path }) => {
  console.log(location);

  const { user } = useAuth0();
  const blocks = useDynamicComponent(data.storyblokEntry, location);
  const CHAT_VISIBILITY =
    JSON.parse(data.storyblokEntry.content).chat_visibility_status ===
    'VISIBLE';
  const { breadcrumb } = pageContext as any;

  const content = data?.storyblokEntry.content;
  const parsedContent = JSON.parse(content);

  const CANONICAL_URL = parsedContent?.canonical_url || '';
  const NO_INDEX = parsedContent?.no_index || '';
  const META_DATA = getMetaData(data?.storyblokEntry.content);
  const FULL_SLUG = data?.storyblokEntry.full_slug;
  const URL_PARTS = FULL_SLUG.split('/');

  const ALTERNATIVE_VERSIONS = data?.storyblokEntry.alternates;
  const LANG_PREFIX = (URL_PARTS?.length >= 1 && URL_PARTS[0]) || '';

  const PAGE_SLUG = FULL_SLUG.replace(`${LANG_PREFIX}/`, '').replace(
    `${process.env.DOMAIN_FOLDER}`,
    ''
  );
  const isAlternativeNav = PAGE_SLUG === '/';

  const headerBlok = getRequiredHeaderBlok('header', LANG_PREFIX);
  const footerBlok = getRequiredFooterBlok('footer', LANG_PREFIX);

  const HEADER = headerBlok && (
    <DynamicComponent blok={JSON.parse(headerBlok.content)} />
  );
  const FOOTER = footerBlok && (
    <DynamicComponent blok={JSON.parse(footerBlok.content)} />
  );

  return (
    <WebChatContext.Provider value={{ visibility: CHAT_VISIBILITY }}>
      <AuthContext.Provider value={{ user }}>
        <Layout
          langPrefix={LANG_PREFIX}
          isAlternativeNav={isAlternativeNav}
          breadcrumb={breadcrumb}
          header={HEADER}
          footer={FOOTER}
        >
          {META_DATA && (
            <Meta
              metaData={META_DATA}
              url={`${process.env.SITE_URL?.replace(/\/$/, '')}${
                location.pathname
              }`}
              canonical={CANONICAL_URL}
              noIndex={NO_INDEX}
              alternatives={ALTERNATIVE_VERSIONS}
              pageSlug={PAGE_SLUG}
              langPrefix={LANG_PREFIX}
              breadcrumb={breadcrumb}
            />
          )}
          {blocks}
        </Layout>
      </AuthContext.Provider>
    </WebChatContext.Provider>
  );
};

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id
      name
      full_slug
      content
      alternates {
        id
        full_slug
      }
    }
  }
`;

export default StoryblokEntryTemplate;
